import React, { useState, useEffect } from 'react';
import { CircularProgress, Box, styled } from '@mui/material';
import axios from 'axios';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import Plot from 'react-plotly.js';
import 'react-pivottable/pivottable.css';


const PlotlyRenderers = createPlotlyRenderers(Plot);
const renderers = Object.assign({}, TableRenderers, PlotlyRenderers);

function formatDate(isoString) {
  if (!isoString) return 'N/A';
  const date = new Date(isoString);
  return date.toLocaleDateString();
}

const StyledPivotTableUI = styled(PivotTableUI)(({ theme }) => ({
  '& .pvtTable': {
    width: '100%',
    borderCollapse: 'collapse',
  },
  '& .pvtTable th, & .pvtTable td': {
    border: '1px solid #ddd',
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  '& .pvtTable th': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const Reports = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pivotState, setPivotState] = useState({});

  const fetchData = async () => {
    const perfilId = localStorage.getItem('perfilId');
    const groupId = localStorage.getItem('groupId');
    const userId = localStorage.getItem('userId');

    let apiEndpoint;
    if (perfilId === '1' || perfilId === '2') {
      apiEndpoint = 'https://echo.tsmpatagonia.com.ar:3001/api/stReports';
    } else if (perfilId === '3') {
      apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stReports/group/${groupId}`;
    } else if (perfilId === '4') {
      apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stReports/user/${userId}`;
    }

    try {
      const response = await axios.get(apiEndpoint, {
        headers: {
          accept: 'application/json',
        },
      });

      const dataArray = Array.isArray(response.data) ? response.data : [response.data];

      const mappedRows = dataArray.map((item) => ({
        addressReferenceId: item.addressReferenceId || 'N/A',
        serviceLineNumber: item.serviceLineNumber || 'N/A',
        nickname: item.nickname || 'N/A',
        productReferenceId: item.productReferenceId || 'N/A',
        delayedProductId: item.delayedProductId || 'N/A',
        optInProductId: item.optInProductId || 'N/A',
        startDate: formatDate(item.startDate),
        endDate: formatDate(item.endDate),
        publicIp: item.publicIp ? 'Yes' : 'No',
        active: item.active ? 'Active' : 'Inactive',
        IdClient: item.IdClient || 'N/A',
        addressLines: item.addressLines || 'N/A',
        locality: item.locality || 'N/A',
        administrativeArea: item.administrativeArea || 'N/A',
        administrativeAreaCode: item.administrativeAreaCode || 'N/A',
        region: item.region || 'N/A',
        regionCode: item.regionCode || 'N/A',
        postalCode: item.postalCode || 'N/A',
        metadata: item.metadata || 'N/A',
        formattedAddress: item.formattedAddress || 'N/A',
        latitude: item.latitude || 'N/A',
        longitude: item.longitude || 'N/A',
        id: item.id || 'N/A',
        accountNumber: item.accountNumber || 'N/A',
        isoCurrencyCode: item.isoCurrencyCode || 'N/A',
        isMobilePlan: item.isMobilePlan ? 'Yes' : 'No',
        activeFrom: formatDate(item.activeFrom),
        subscriptionActiveFrom: formatDate(item.subscriptionActiveFrom),
        subscriptionEndDate: formatDate(item.subscriptionEndDate),
        overageName: item.overageName || 'N/A',
        overageDescription: item.overageDescription || 'N/A',
        isOptedIntoOverage: item.isOptedIntoOverage ? 'Yes' : 'No',
        lastUpdated: formatDate(item.lastUpdated),
        cycleStartDate: formatDate(item.cycleStartDate),
        cycleEndDate: formatDate(item.cycleEndDate),
        totalPriorityGB: item.totalPriorityGB || 0,
        totalStandardGB: item.totalStandardGB || 0,
        totalOptInPriorityGB: item.totalOptInPriorityGB || 0,
        totalNonBillableGB: item.totalNonBillableGB || 0,
        restricted: item.restricted || 0,
        unrestricted: item.unrestricted || 0,
        pricePerGB: item.pricePerGB || 0,
        usageLimitGB: item.usageLimitGB || 0,
        consumedAmountGB: item.consumedAmountGB || 0,
        overageAmountGB: item.overageAmountGB || 0,
        overagePrice: item.overagePrice || 0,
        productId: item.productId || 'N/A',
        dataOverageType: item.dataOverageType || 'N/A',
        userTerminalId: item.userTerminalId || 'N/A',
        kitSerialNumber: item.kitSerialNumber || 'N/A',
        dishSerialNumber: item.dishSerialNumber || 'N/A',
        routerId: item.routerId || 'N/A',
        configId: item.configId || 'N/A',
        directLinkToDish: item.directLinkToDish || 'N/A',
        hardwareVersion: item.hardwareVersion || 'N/A',
        lastBonded: formatDate(item.lastBonded),
        ClientName: item.ClientName || 'N/A',
        AccountNumber: item.AccountNumber || 'N/A',
        ClientId: item.ClientId || 'N/A',
        ClientSecret: item.ClientSecret || 'N/A',
        // Nuevos campos de fechas
        SLI_startDate_year: item.SLI_startDate_year || 'N/A',
        SLI_startDate_month: item.SLI_startDate_month || 'N/A',
        SLI_endDate_year: item.SLI_endDate_year || 'N/A',
        SLI_endDate_month: item.SLI_endDate_month || 'N/A',
        SLB_startDate_year: item.SLB_startDate_year || 'N/A',
        SLB_startDate_month: item.SLB_startDate_month || 'N/A',
        SLB_endDate_year: item.SLB_endDate_year || 'N/A',
        SLB_endDate_month: item.SLB_endDate_month || 'N/A',
        SLB_activeFrom_year: item.SLB_activeFrom_year || 'N/A',
        SLB_activeFrom_month: item.SLB_activeFrom_month || 'N/A',
        SLB_subscriptionActiveFrom_year: item.SLB_subscriptionActiveFrom_year || 'N/A',
        SLB_subscriptionActiveFrom_month: item.SLB_subscriptionActiveFrom_month || 'N/A',
        SLB_subscriptionEndDate_year: item.SLB_subscriptionEndDate_year || 'N/A',
        SLB_subscriptionEndDate_month: item.SLB_subscriptionEndDate_month || 'N/A',
        SLB_lastUpdated_year: item.SLB_lastUpdated_year || 'N/A',
        SLB_lastUpdated_month: item.SLB_lastUpdated_month || 'N/A',
        BC_cycleStartDate_year: item.BC_cycleStartDate_year || 'N/A',
        BC_cycleStartDate_month: item.BC_cycleStartDate_month || 'N/A',
        BC_cycleEndDate_year: item.BC_cycleEndDate_year || 'N/A',
        BC_cycleEndDate_month: item.BC_cycleEndDate_month || 'N/A',
        RI_lastBonded_year: item.RI_lastBonded_year || 'N/A',
        RI_lastBonded_month: item.RI_lastBonded_month || 'N/A',
      }));

      setRows(mappedRows);
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '90vh',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <StyledPivotTableUI
        data={rows}
        onChange={(s) => setPivotState(s)}
        renderers={renderers}
        {...pivotState}
        className="responsive-pivot-table"
      />
    </Box>
  );
};

export default Reports;
