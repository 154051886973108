import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const TopBar = ({ toggleSidebar, toggleTheme }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [logoDark, setLogoDark] = useState(null);
  const [logoLight, setLogoLight] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    if (!userId || !token) {
      setError('User not authenticated');
      return;
    }

    const fetchLogos = async () => {
      try {
        const response = await axios.get(`https://echo.tsmpatagonia.com.ar:3001/api/logos/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Asignamos las cadenas base64 a los estados
        setLogoDark(response.data.logoDark);
        setLogoLight(response.data.logoLight);

      } catch (error) {
        console.error('Error fetching logos:', error);
        setError('Failed to load logos');
      }
    };

    fetchLogos();
  }, []);

  // Función para verificar si el logo es válido (no null, undefined o cadena vacía)
  const isLogoValid = (logo) => {
    return logo && logo.trim() !== '';
  };

  const defaultLogoDark = 'https://tsmpatagonia.com.ar/wp-content/uploads/2023/07/Recurso-2.png';
  const defaultLogoLight = 'https://tsmpatagonia.com.ar/wp-content/uploads/2023/07/Recurso-1.png';

  const logoSrc = theme.palette.mode === 'dark'
    ? (isLogoValid(logoDark) ? logoDark : defaultLogoDark)
    : (isLogoValid(logoLight) ? logoLight : defaultLogoLight);

  return (
    <AppBar position="static">
      <Toolbar>
        {isSmallScreen && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleSidebar}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography variant="h6" noWrap sx={{ flexGrow: 1, fontSize: isSmallScreen ? '1rem' : '1.5rem' }}>
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <Box
              component="img"
              src={logoSrc}
              alt="Logo"
              sx={{ ml: 2, height: 40, objectFit: 'contain' }}
            />
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
