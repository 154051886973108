import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Drawer,
  Box,
  Button,
  Grid,
  useTheme,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MapIcon from '@mui/icons-material/Map';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ContrastIcon from '@mui/icons-material/Contrast';
import LogoutIcon from '@mui/icons-material/Logout';

const Sidebar = ({ isOpen, toggleTheme, profileUpdated }) => {
  const groupId = localStorage.getItem('groupId');
  const userId = localStorage.getItem('userId');
  const name = localStorage.getItem('name');
  const lastName = localStorage.getItem('lastName');
  const avatarBlob = localStorage.getItem('avatar');
  const [imageUrl, setImageUrl] = useState(null);

  const [expanded, setExpanded] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const perfilId = parseInt(localStorage.getItem('perfilId'), 10);

  const [anchorEl, setAnchorEl] = useState(null); // State for avatar menu
  const open = Boolean(anchorEl);

  // Convertir el blob a una URL de imagen cuando el avatar esté disponible
  useEffect(() => {
    if (avatarBlob) {
      const blobUrl = `data:image/jpeg;base64,${avatarBlob}`;
      setImageUrl(blobUrl);
    }
  }, [avatarBlob, profileUpdated]); // Se vuelve a ejecutar cuando `profileUpdated` cambia

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('perfilId');
    localStorage.removeItem('groupId');
    localStorage.removeItem('name');
    localStorage.removeItem('lastName');
    localStorage.removeItem('avatar');
    navigate('/login');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { text: 'Service List', path: '/', icon: <SatelliteAltIcon /> },
    { text: 'Monitor DashBoard', path: '/dashboard', icon: <DashboardIcon /> },
    { text: 'Service Plan', path: '/userterminals', icon: <PublicIcon /> },
    { text: 'Data DashBoard', path: '/datadashboard', icon: <DashboardIcon /> },
    { text: 'Global Map', path: '/maps', icon: <MapIcon /> },
    { text: 'Alarms', path: '/alarm', icon: <NotificationImportantIcon /> },
    ...(perfilId === 1
      ? [
          { text: 'Reports', path: '/reports', icon: <NotificationImportantIcon /> },
          { text: 'Routers', path: '/routersadmin', icon: <SettingsIcon /> },
        ]
      : []),
  ];

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: expanded ? { xs: '50vw', sm: '30vw', md: '20vw', lg: '15vw' } : { xs: '15vw', sm: '10vw', md: '8vw', lg: '5vw' },
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: expanded ? { xs: '50vw', sm: '30vw', md: '20vw', lg: '15vw' } : { xs: '15vw', sm: '10vw', md: '8vw', lg: '5vw' },
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflow: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="column" spacing={1} sx={{ p: 2 }}>
          {menuItems.map((item) => (
            <Grid item key={item.text}>
              <Button
                fullWidth
                variant="contained"
                size="medium"
                color={location.pathname === item.path ? 'secondary' : 'inherit'}
                onClick={() => navigate(item.path)}
                sx={{
                  justifyContent: expanded ? 'flex-start' : 'center',
                  backgroundColor: location.pathname === item.path ? 'secondary.main' : 'transparent',
                  color: location.pathname === item.path ? '#fff' : 'inherit',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
                startIcon={expanded ? item.icon : null}
              >
                {expanded ? item.text : item.icon}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: expanded ? 'space-between' : 'center',
          alignItems: 'center',
          flexDirection: expanded ? 'row' : 'column',
          gap: 1,
        }}
      >
        <Avatar
          src={imageUrl}
          alt={`${name} ${lastName}`}
          sx={{ width: 64, height: 64, border: 1, cursor: 'pointer' }}
          onMouseOver={handleMenuOpen}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          sx={{ mt: 1 }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            sx: { mt: -1, ml: 1 }, // Adjust top margin to align with Avatar
          }}
        >
          <MenuItem onClick={() => {
            navigate('/useradmin');
            handleMenuClose(); // Cerrar el menú al hacer clic
          }}>
            <PersonIcon sx={{ margin: 1 }} />
            User Settings
          </MenuItem>
          <MenuItem onClick={toggleTheme}>
            <ContrastIcon sx={{ margin: 1 }} />
            Toggle Theme
          </MenuItem>
          <MenuItem onClick={handleToggle}>
            <SwapHorizIcon sx={{ margin: 1 }} />
            Expand Sidebar
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LogoutIcon sx={{ margin: 1 }} />
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
