import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Sensors from './components/Sensors';
import Alerts from './components/Alerts';
import Settings from './components/Settings';
import Reports from './components/Reports';
import Sidebar from './components/Sidebar';
import TopBar from './components/TopBar';
import Login from './components/Login';
import Maps from './components/Maps';
import UserTerminals from './components/UserTerminals';
import DashBoardBilling from './components/DashBoardBilling';
import Routers from './components/Routers'; // Adjust the path as necessary
import RoutersAdmin from './components/RoutersAdmin'; // Adjust the path as necessary
import UserAdmin from './components/userAdmin'; // Adjust the path as necessary
import PrivateRoute from './components/PrivateRoute';
import { Container, Box } from '@mui/material';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import '@fontsource/asap'; // Importa la fuente que desees


const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Default closed for small screens
  const [themeMode, setThemeMode] = useState('system'); // 'light', 'dark', or 'system'

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Theme toggling logic
  const toggleTheme = () => {
    setThemeMode(prevMode => (prevMode === 'light' ? 'dark' : prevMode === 'dark' ? 'system' : 'light'));
  };

  const theme = useMemo(() => {
    const mode = themeMode === 'system' ? (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light') : themeMode;

    return createTheme({
      typography: {
        fontFamily: 'Asap, Arial, sans-serif', // Aquí pones la fuente que deseas usar
      },
      palette: {
        mode: mode,
        primary: {
          main: '#1976d2', // Default blue
        },
        secondary: {
          main: '#dc004e', // Default red
        },
      },
    });
  }, [themeMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={
            <PrivateRoute>
              <Box sx={{ display: 'flex' }}>
                <Sidebar isOpen={isSidebarOpen} toggleTheme={toggleTheme}/>
                <Box sx={{ flexGrow: 1 }}>
                  <TopBar toggleSidebar={toggleSidebar} toggleTheme={toggleTheme} />
                  <Container maxWidth="xl" sx={{ mt: 2 }}>
                    <Routes>
                      <Route path="/sensors" element={<Sensors />} />
                      <Route path="/alerts" element={<Alerts />} />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/reports" element={<Reports />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/routersadmin" element={<RoutersAdmin />} />
                      <Route path="/maps" element={<Maps />} />
                      <Route path="/useradmin" element={<UserAdmin />} />
                      <Route path="/userterminals" element={<UserTerminals />} />
                      <Route path="/datadashboard" element={<DashBoardBilling />} />
                      <Route path="/" element={<Routers />} />
                    </Routes>
                  </Container>
                </Box>
              </Box>
            </PrivateRoute>
          } />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
