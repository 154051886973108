import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, CircularProgress, Grid } from '@mui/material';
import axios from 'axios';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Estado de carga
  const navigate = useNavigate();
  
  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true); // Establecer estado de carga

    try {
      // Realizar la solicitud de inicio de sesión
      const response = await axios.post(
        'https://echo.tsmpatagonia.com.ar:3001/api/login', // Actualizar la URL según la nueva API
        {
          email,
          password
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      const { id, perfil_id, grupo_id, token, nombre, avatar, lastName, logoDark, logoLight } = response.data;

      // Guardar datos en localStorage
      localStorage.setItem('token', token); // Guardar token en localStorage
      localStorage.setItem('userId', id); // Guardar userId en localStorage
      localStorage.setItem('perfilId', perfil_id); // Guardar perfil_id en localStorage
      localStorage.setItem('groupId', grupo_id); // Guardar grupo_id en localStorage
      localStorage.setItem('name', nombre);
      localStorage.setItem('lastName', lastName);
      localStorage.setItem('avatar', avatar);


      setLoading(false); // Restablecer estado de carga
      navigate('/'); // Redirigir a la página principal después de iniciar sesión
    } catch (err) {
      console.error('Error durante el inicio de sesión:', err);
      setLoading(false); // Restablecer estado de carga
      setError('Correo electrónico o contraseña incorrectos');
    }
  };

  return (
    <Box sx={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <Grid container sx={{ height: '100%' }}>
        {/* Columna Izquierda */}
        <Grid item xs={0} md={4} sx={{ backgroundColor: '#1B72C4', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            component="img"
            src="https://tsmpatagonia.com.ar/wp-content/uploads/2023/07/Recurso-2.png"
            alt="StarLink Logo"
            sx={{ width: '60%', maxWidth: '300px' }} // Ajustar el tamaño según sea necesario
          />
        </Grid>

        {/* Columna Derecha */}
        <Grid item xs={12} md={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Box sx={{ width: '100%', maxWidth: 400, mx: 'auto', p: 3 }}>
            <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {error && <Typography color="error">{error}</Typography>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading} // Deshabilitar el botón cuando está cargando
              >
                {loading ? <CircularProgress size={24} /> : 'Sign In'}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
