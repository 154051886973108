/* global BigInt */
import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Grid, useMediaQuery, MenuItem, Select, FormControl, InputLabel, Stack, CircularProgress, Button } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from 'axios';
import { Gauge } from '@mui/x-charts/Gauge';
import { LinearProgress } from '@mui/material';
import * as h3 from 'h3-js';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function formatUptime(seconds) {
  const days = Math.floor(seconds / (24 * 3600)); // Calculate total days
  const hours = Math.floor((seconds % (24 * 3600)) / 3600); // Calculate remaining hours
  const minutes = Math.floor((seconds % 3600) / 60); // Calculate remaining minutes
  const secs = seconds % 60; // Calculate remaining seconds

  return `${days}d:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

function formatDate(isoString) {
  const date = new Date(isoString);
  return date.toLocaleDateString();  // This will return the date in the user's locale format
}


mapboxgl.accessToken = 'pk.eyJ1IjoiZnJvY2NvYXIiLCJhIjoiY20wbXo4N3FvMDMwbzJrb21kOXhsY3FlbyJ9.chVdQIlwUlzcsAvuJsbx8g';

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedDeviceId = location.state?.deviceId || ''; // Get the deviceId from state or use a default value

  const [map, setMap] = useState(null);
  const [address, setAddress] = useState(null);
  const [IpData, setIp] = useState(null);

  const [devices, setDevices] = useState([]); 
  const [selectedDevice, setSelectedDevice] = useState(selectedDeviceId); // Initialize with selectedDeviceId

  const [dishData, setDishData] = useState(null);
  const [position, setPosition] = useState([-67.53045749329654, -24.02252433841831]); // Default position
  
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const parseActiveAlert = (alertString) => {
    const alertArray = alertString.replace(/[\[\]]/g, '').split(',').map(Number);
    return alertArray.filter(code => !isNaN(code));
  };

  useEffect(() => {
    const perfilId = localStorage.getItem('perfilId');
    const groupId = localStorage.getItem('groupId');
    const userId = localStorage.getItem('userId');
  
    let apiEndpoint;
    if (perfilId === '1' || perfilId === '2') {
      apiEndpoint = 'https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters';
    } else if (perfilId === '3') {
      apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters/group/${groupId}`;
    } else if (perfilId === '4') {
      apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters/user/${userId}`;
    }

    if (apiEndpoint) {
      axios.get(apiEndpoint)
        .then(response => {
          setDevices(response.data);
          if (selectedDeviceId) {
            setSelectedDevice(selectedDeviceId);
          } else {
            setSelectedDevice(response.data[0]?.uti_userTerminalId || '');
          }
        })
        .catch(error => {
          console.error('Error fetching devices:', error);
        });
    }
  }, [selectedDeviceId]);

  const fetchData = () => {
    if (selectedDevice) {
      axios.get(`https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters/userterminal/${selectedDevice}`)
        .then(dishResponse => {
          if (dishResponse.data && dishResponse.data.length > 0) {
            const responseData = dishResponse.data[0];
            //console.log(responseData)
            setDishData(responseData);
            if (responseData.ut_H3CellId) {
              const H3CellIdHex = BigInt(responseData.ut_H3CellId).toString(16);
              //console.log(H3CellIdHex)
              const [lat, lon] = h3.cellToLatLng(H3CellIdHex);
              setPosition([lon, lat]);
            }
          } else {
            setDishData(null); // Set dishData to null when there's no data
            setPosition(null);
          }
        })
        .catch(error => {
          console.error('Error fetching device data:', error);
          //setDishData(null); // Also set dishData to null on error
          setPosition(null);
        });
    } else {
      setDishData(null);
      setPosition(null);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch initially

    const interval = setInterval(() => {
      fetchData(); // Fetch every 15 seconds
    }, 15000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [selectedDevice]);

  useEffect(() => {
    const mapInstance = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/froccoar/clzru7x0100fn01pbf08t4v1v',
      zoom: 10,
      pitch: 45,
      bearing: 0,
    });

    mapInstance.on('style.load', () => {
      if (position) {
        new mapboxgl.Marker({ color: '#1976d2' })
          .setLngLat(position)
          .setPopup(new mapboxgl.Popup().setText('Ultima Posición conocida'))
          .addTo(mapInstance);

        mapInstance.setCenter(position); // Move the center after style is loaded
        mapInstance.addControl(new mapboxgl.NavigationControl());
        setMap(mapInstance);

        let bearing = 0;
        let isRotating = true;

        function rotateCamera() {
          if (isRotating) {
            bearing += 0.1;
            mapInstance.rotateTo(bearing, { duration: 0 });
            requestAnimationFrame(rotateCamera);
          }
        }

        rotateCamera();

        mapInstance.on('mousedown', () => {
          isRotating = false;
        });
      }
    })

    return () => mapInstance.remove();
  }, []);

  useEffect(() => {
    if (map && position) {
      map.setCenter(position);

      if (dishData?.ut_H3CellId) {
        const H3CellIdHex = BigInt(dishData.ut_H3CellId).toString(16);
        const hexBoundary = h3.cellToBoundary(H3CellIdHex);

        const geoJsonPolygon = {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [hexBoundary.map(coord => [coord[1], coord[0]])] // Map to [lon, lat]
          }
        };

        if (map.getSource('hexagon')) {
          map.getSource('hexagon').setData(geoJsonPolygon);
        } else {
          map.addSource('hexagon', {
            type: 'geojson',
            data: geoJsonPolygon
          });

          map.addLayer({
            id: 'hexagon',
            type: 'fill',
            source: 'hexagon',
            layout: {},
            paint: {
              'fill-color': '#099',
              'fill-opacity': 0.3
            }
          });
        }
      }
    }
  }, [position, map, dishData]);

  const [onlineStatus, setOnlineStatus] = useState(true); // State to hold online/offline status
  const [buenosAiresTime, setBuenosAiresTime] = useState(''); // State to store converted time


  const checkOnlineStatus = (UtcTimestampNs) => {
    const timestampMs = UtcTimestampNs / 1e6;
    const buenosAiresTimeString = new Date(timestampMs).toLocaleString("en-US", {
      timeZone: "America/Argentina/Buenos_Aires",
      hour12: false,
    });

    const buenosAiresDate = new Date(buenosAiresTimeString);
    const currentDate = new Date().toLocaleString("en-US", {
      timeZone: "America/Argentina/Buenos_Aires",
      hour12: false,
    });

    const currentDateObj = new Date(currentDate);
    const timeDifference = Math.abs(currentDateObj - buenosAiresDate);
    const isOnline = timeDifference < 600000; // 10 minutes

    // Return both the time string and the online status
    return {
      buenosAiresTime: buenosAiresTimeString,
      isOnline: isOnline
    };
  };

  useEffect(() => {
    if (dishData && dishData.ut_UtcTimestampNs) {
      const { buenosAiresTime, isOnline } = checkOnlineStatus(dishData.ut_UtcTimestampNs);
      setOnlineStatus(isOnline); // Update the state
      setBuenosAiresTime(buenosAiresTime); // Use the correct variable here
    } else {
      setOnlineStatus(false);
      setBuenosAiresTime('Unknown');
    }

    const fetchAlarmData = async () => {
      if (!dishData?.ut_ActiveAlert || dishData.ut_ActiveAlert === '[]') {
        setAlerts([]);  // Clear the alerts state
        setLoading(false);  // Stop loading
        return;
      }

      setLoading(true);

      // Parse alert codes
      const alertCodes = parseActiveAlert(dishData.ut_ActiveAlert);

      try {
        // Fetch data for each alert code
        const alertPromises = alertCodes.map(async (code) => {
          const response = await axios.get(`https://echo.tsmpatagonia.com.ar:3001/api/alarmdata/${code}`);
          return response.data;
        });

        // Wait for all promises
        const alertData = await Promise.all(alertPromises);
        const flattenedAlerts = alertData.flatMap(data => Array.isArray(data) ? data : [data]);
        //console.log("ALARM", alertCodes, flattenedAlerts)

    // Update state
        setAlerts(flattenedAlerts);
      } catch (error) {
        console.error('Error fetching alarm data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAlarmData();

    const fetchAddressData = async () => {
      if (!dishData?.sli_addressReferenceId || dishData.sli_addressReferenceId === '') {
        setLoading(false);
        setAddress(null);
        return;
      }
    
      setLoading(true);
    
      const code = dishData.sli_addressReferenceId;
    
      try {
        const response = await axios.get(`https://echo.tsmpatagonia.com.ar:3001/api/address/${code}`);
        //console.log(response.data)
        if (response.data && response.data.length > 0) {
          const addressData = response.data[0];
          setAddress(addressData);
        } else {
          setAddress(null);
        }
      } catch (error) {
        console.error('Error fetching address data:', error);
        setAddress(null);
      } finally {
        setLoading(false);
      }
    };
    
    fetchAddressData();

    const fetchIpData = async () => {
      if (!dishData?.uti_userTerminalId || dishData.uti_userTerminalId === '') {
        setLoading(false);
        setIp(null);
        return;
      }
    
      setLoading(true);
    
      const code = 'ip-ut'+dishData.uti_userTerminalId;
    
      try {
        const response = await axios.get(`https://echo.tsmpatagonia.com.ar:3001/api/ipdata/${code}`);
    
        if (response.data && response.data.length > 0) {
          const IpData = response.data[0];
          setIp(IpData);
        } else {
          setIp(null);
        }
      } catch (error) {
        console.error('Error fetching address data:', error);
        setIp(null);
      } finally {
        setLoading(false);
      }
    };
    
    fetchIpData();

  }, [dishData]);

  return (
    <Box sx={{ height: '80vh', display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Grid container spacing={2} sx={{ flex: '1 1 auto', height: '100%' }}>
        {/* Left Panel */}
        <Grid item xs={5}>
          <Stack container direction="column" spacing={0} sx={{ height: '100%' }}>
            {/* Device Selector */}
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <FormControl fullWidth>
                  <InputLabel id="device-select-label">Device</InputLabel>
                  <Select
                    labelId="device-select-label"
                    id="device-select"
                    value={selectedDevice}
                    label="Device"
                    onChange={(e) => setSelectedDevice(e.target.value)}
                  >
                    {devices.map((device) => (
                      <MenuItem key={device.uti_userTerminalId} value={device.uti_userTerminalId}>
                        {device.sli_nickname || device.uti_kitSerialNumber}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    navigate(`/datadashboard`, {
                      state: { serviceLineNumber: dishData?.sli_serviceLineNumber },
                    })
                  }
                  sx={{
                    width: '100%',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    textAlign: 'center',
                  }}
                >
                  {dishData?.sli_serviceLineNumber}
                </Button>
              </Grid>
            </Grid>

            {/* Device Status */}
            <Grid item sx={{ flex: '1 1 auto' }}>
              <Paper elevation={0} sx={{ p: 2, height: '100%' }}>
                <Typography variant={isSmallScreen ? "body2" : "h6"}>Device Status</Typography>
                <>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ fontWeight: 'bold' }}>
                    {onlineStatus ? 'Online ' : 'Offline '}
                  </Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h12"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                    Last connection {buenosAiresTime || 'Unknown'}
                  </Typography>
                </>
              </Paper>
            </Grid>

            {/* Uplink and Downlink Gauges in one row */}
            <Grid container spacing={2}>
              {/* Uplink Gauge */}
              <Grid item xs={6}>
                <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', borderRadius: "0" }}>
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Uplink</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary' }}>Upload speed</Typography>
                  <Gauge
                    innerRadius="60%"
                    outerRadius="100%"
                    width={200}
                    height={200}
                    valueMax={400}
                    value={dishData?.ut_UplinkThroughput || 0}
                    startAngle={-110}
                    endAngle={110}
                    text={() => `${dishData?.ut_UplinkThroughput || 0} Mbps`}
                    sx={{ mx: 'auto' }}
                  />
                </Paper>
              </Grid>

              {/* Downlink Gauge */}
              <Grid item xs={6}>
                <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', borderRadius: "0" }}>
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Downlink</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary' }}>Download speed</Typography>
                  <Gauge
                    innerRadius="60%"
                    outerRadius="100%"
                    width={200}
                    height={200}
                    valueMax={400}
                    value={dishData?.ut_DownlinkThroughput || 0}
                    startAngle={-110}
                    endAngle={110}
                    text={() => `${dishData?.ut_DownlinkThroughput || 0} Mbps`}
                    sx={{ mx: 'auto' }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Stack>
        </Grid>

        {/* Map Section */}
        <Grid item xs={7}>
          <Paper elevation={2} sx={{ p: 0, height: '100%', width: '100%', borderRadius: "0" }}>
            <Box id="map" sx={{ height: '100%', width: '100%', borderRadius: "0" }} />
          </Paper>
        </Grid>
      </Grid>

      {/* Network Stats */}
      <Grid container spacing={2} sx={{ flex: '1 1 20%' }}>
        {['Ping Latency', 'Clients', 'WAN Tx', 'WAN Rx', 'Ping Drop', 'Uptime'].map((label, index) => (
          <Grid item xs={2} key={label}>
            <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'top', borderRadius: "0" }}>
              <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>{label}</Typography>
              <Typography variant={isSmallScreen ? "body2" : "h5"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                {label === 'Ping Latency' && `${dishData?.r_InternetPingLatencyMs || 0} ms`}
                {label === 'Clients' && `${dishData?.r_Clients || 0}`}
                {label === 'WAN Tx' && `${((dishData?.r_WanTxBytes || 0) / (1024 * 1024 * 1024)).toFixed(2)} GB`}
                {label === 'WAN Rx' && `${((dishData?.r_WanRxBytes || 0) / (1024 * 1024 * 1024)).toFixed(2)} GB`}
                {label === 'Ping Drop' && `${dishData?.r_InternetPingDropRate || 0} %`}
                {label === 'Uptime' && `${formatUptime(dishData?.ut_Uptime || 0)}`}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Lower Panel with various metrics */}
      <Grid container spacing={2} sx={{ flex: '1 1 40%' }}>
        <Grid item xs={2}>
          <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'top', borderRadius: "0" }}>
            <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Signal Quality</Typography>
            <Box sx={{ width: '100%', mt: 2 }}>
              <Typography level="body-xs" textColor="white" variant={isSmallScreen ? "body2" : "h6"} align="center">
                {Math.round((dishData?.ut_SignalQuality || 0) * 100)}%
              </Typography>
              <LinearProgress
                variant="determinate"
                size="sm"
                thickness={32}
                value={(dishData?.ut_SignalQuality || 0) * 100}
                sx={{ height: 25, borderRadius: 0 }}
              >
              </LinearProgress>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'top', borderRadius: "0" }}>
            {IpData ? (
              <>
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>IpV4:</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{IpData.Ipv4}</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>IpV6:</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{IpData.Ipv6Ue}</Typography>
              </>
            ) : (
              <>
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>IpV4:</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>N/A</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>IpV6:</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>N/A</Typography>
                
              </>
            )}
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'top', borderRadius: "0" }}>
            <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Alerts</Typography>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                {Array.isArray(alerts) && alerts.length > 0 ? (
                alerts.map((alert, index) => (
                  <Typography key={index} variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                    [{alert.code}] {alert.human_readable_description || `Alert Code ${alert.code}`}
                  </Typography>
                  ))
                ) : (
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                    No alarms
                  </Typography>
                )}
              </>
            )}
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'top', borderRadius: "0" }}>
            <>
              <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Dish Id:</Typography>
                <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>ut{dishData?.uti_userTerminalId || 'N/A'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Router Id:</Typography>
                <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{dishData?.r_DeviceId || 'N/A'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Service Line number:</Typography>
                <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{dishData?.uti_serviceLineNumber || 'N/A'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>KitNumber:</Typography>
                <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{dishData?.uti_kitSerialNumber || 'N/A'}</Typography>
              </Box>
            </>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ flex: '1 1 40%', mb: 4 }}>
        <Grid item xs={6}>
          <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'top', borderRadius: "0" }}>
            {address ? (
              <>
                <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Region:</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{address.region}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Locality:</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{address.locality}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Address Line:</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{address.addressLines}</Typography>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Region:</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>N/A</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Locality:</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>N/A</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Address Line:</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>N/A</Typography>
                </Box>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'top', borderRadius: "0" }}>
            <>
              <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Account:</Typography>
                <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{dishData?.ca_AccountNumber || 'N/A'} / {dishData?.ca_ClientName || 'N/A'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Product:</Typography>
                <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{dishData?.sli_productReferenceId || 'N/A'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Start date:</Typography>
                <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{dishData?.sli_startDate ? formatDate(dishData.sli_startDate) : 'N/A'}</Typography>
              </Box>
            </>
          </Paper>
        </Grid>
      </Grid>

    </Box>
  );
};

export default Dashboard;
